import React from 'react';
import './InteractiveLabs.css'; // Importing the CSS file
import useSound from '../Sound';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function InteractiveLabs() {
    const playClickSound = useSound('/ui-click-43196.mp3'); // Local file path
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const navigate = useNavigate(); // Hook to programmatically navigate

    const handleSound = () => {
        playClickSound();
    };

    const handleAccess = (path) => {
        playClickSound();
        if (userInfo) {
            navigate(path); // Navigate if the user is logged in
        } else {
            alert("Please login to check out interactive applications, they're free!");
            navigate("/Login"); // Redirect to login page if not logged in
        }
    };

    return (
        <div className="outer-lab-container">
            <div className="lab-container">
                <h1 className="lab-title">Interactive Labs</h1>
                <div className="lab-sections">
                    <div className="lab-section">
                        <h2>Biochemistry/Biology Labs</h2>
                        <ul className="lab-list">
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/MoleculeViewer')}>Macromolecule Visualizer</a>
                            </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/Anatomy')}>Anatomy Lab</a>
                            </li>
                        </ul>
                    </div>
                    <div className="lab-section">
                        <h2>Organic/General Chemistry Labs</h2>
                        <ul className="lab-list">
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/chemstuff')}>Molecular Editor</a>
                            </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/atom')}>Periodic Table Bohr Model</a>
                            </li>
                        </ul>
                    </div>
                    <div className="lab-section">
                        <h2>Physics Lab</h2>
                        <ul className="lab-list">
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/Physicslab1')}>Free Falling Ball</a>
                            </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/Physicslab2')}>Momentum</a>
                            </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/Physicslab3')}>Wave Sketch</a>
                            </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/Physicslab5')}>Wave Interference</a>
                            </li>
                        </ul>
                    </div>
                    <div className="lab-section">
                        <h2>Algebra Labs</h2>
                        <ul className="lab-list">
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/Algebralab2')}>Set Operations</a>
                            </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/Algebralab3')}>Function Graph</a>
                            </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/Algebralab4')}>Solve Equation</a>
                            </li>
                        </ul>
                    </div>
                    <div className="lab-section">
                        <h2>Supervised Machine Learning Labs</h2>
                        <ul className="lab-list">
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/MLmodel')}>Linear Regression and Mean Squared Error Cost Function</a>
                            </li>
                    
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InteractiveLabs;
