import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const PracticeThree = () => {
    const containerRef = useRef(null); // Create a ref for the container
    const sphereRef = useRef(null); // Create a ref for the container

    const initscene = () =>{

        const container = containerRef.current;
        if (!container) {
            console.error("Tweaking B")
        }
        container.innerHTML = ""
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 1000);
        camera.position.set(0, 0, 10);

        const cubeGeometry = new THREE.BoxGeometry(1,1,1);
        const cubeMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff })
        const cube = new THREE.Mesh(cubeGeometry,cubeMaterial)

        cube.position.set(0,0,0)
        scene.add(cube)

        const sphereGeo = new THREE.SphereGeometry(1);
        const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        const sphere = new THREE.Mesh(sphereGeo,sphereMaterial);
        sphere.position.set(0,2,0);
        sphereRef.current = sphere; // Store sphere in ref

        scene.add(sphere);
        
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(container.clientWidth, container.clientHeight);
        container.appendChild(renderer.domElement);

            // Initialize OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // Smooth controls
            // Animate the scene
    const animate = () => {
        
        requestAnimationFrame(animate);
       // cube.rotation.x += 0.01;
        //cube.rotation.y += 0.01;
        controls.update(); // Update controls for smooth interaction

        renderer.render(scene, camera);
      };
      animate();

         // Handle window resize
    window.addEventListener("resize", () => {
        const width = container.clientWidth;
        const height = container.clientHeight;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
      });
      // Key event listener for moving the sphere
 
    }

    const handleKeyDown = (event) => {
        if (!sphereRef.current) return;
    
        switch (event.key) {
          case 'ArrowUp': // Move up
            sphereRef.current.position.y += 0.1;
            break;
          case 'ArrowDown': // Move down
            sphereRef.current.position.y -= 0.1;
            break;
          case 'ArrowLeft': // Move left
            sphereRef.current.position.x -= 0.1;
            break;
          case 'ArrowRight': // Move right
            sphereRef.current.position.x += 0.1;
            break;
          default:
            break;
        }
      };
    

  // Initialize the scene in useEffect when component mounts
  useEffect(() => {
    if (containerRef.current) {
      initscene('practicecontainer');
      window.addEventListener('keydown', handleKeyDown); // Add key event listener

    }
  }, []);
  return (
    <div>
      <div ref={containerRef} style={{ width: '100%', height: '100vh' }}></div>
    </div>
  )
}

export default PracticeThree
