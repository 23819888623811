// LiveQuizPage.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';  // Importing Axios
import './livequizpage.css';

function LiveQuizPage() {
    const { assessmentId } = useParams();
    const navigate = useNavigate();
    const access_token = useSelector(state => state.userLogin.userInfo.access);

    const [quizDetails, setQuizDetails] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [totalAnswers, setTotalAnswers] = useState(0); // Real-time answer count
    const [score, setScore] = useState(0); // User score
    const [isCompleted, setIsCompleted] = useState(false);
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        // Initialize WebSocket connection for live quiz
        const newSocket = new WebSocket(`wss://api.andrewslearning.com/wschat/livequiz/${23}}/`);
        setSocket(newSocket);

        newSocket.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (data.type === 'quiz_question') {
                setCurrentQuestion(data);
                setSelectedAnswer(null);
                setTotalAnswers(0); // Reset answer count for each new question
            } else if (data.type === 'quiz_update') {
                setTotalAnswers(data.total_answers); // Update answer count in real-time
                if (data.score) {
                    setScore(prevScore => prevScore + data.score); // Update score based on answer timing
                }
            }
        };

        newSocket.onclose = () => {
            console.error('WebSocket closed unexpectedly');
        };

        return () => {
            if (newSocket) newSocket.close();
        };
    }, [assessmentId]);

    // Fetch quiz details
    useEffect(() => {
        const fetchQuizDetails = async () => {
            try {
                const response = await axios.get(`https://api.andrewslearning.com/api/assessments/${assessmentId}`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                setQuizDetails(response.data);
            } catch (error) {
                console.error("Error fetching quiz details:", error);
            }
        };
        fetchQuizDetails();
    }, [assessmentId, access_token]);

    const handleAnswerChange = (answer) => {
        setSelectedAnswer(answer);
    };

    const handleSubmitAnswer = () => {
        if (currentQuestion && selectedAnswer && socket) {
            socket.send(JSON.stringify({
                type: 'submit_answer',
                user_id: access_token,
                question_id: currentQuestion.id,
                answer: selectedAnswer,
            }));
        }
    };

    const handleEndQuiz = () => {
        if (socket) {
            socket.send(JSON.stringify({ type: 'end_test' }));
            setIsCompleted(true);
        }
    };

    const renderQuestionChoices = () => {
        if (!currentQuestion) return null;

        return currentQuestion.choices.map((choice, index) => (
            <div key={index} className="quiz-choice">
                <input
                    type="radio"
                    id={`choice-${index}`}
                    name="answer"
                    value={choice.text}
                    checked={selectedAnswer === choice.text}
                    onChange={() => handleAnswerChange(choice.text)}
                />
                <label htmlFor={`choice-${index}`}>
                    {choice.image && (
                        <img 
                            className="quiz-choice-image" 
                            src={choice.image} 
                            alt="Choice" 
                        />
                    )}
                    {choice.text}
                </label>
            </div>
        ));
    };

    if (!quizDetails) return <div>Loading quiz...</div>;

    return (
        <div className="outer-quiz-container">
            <div className="quiz-container">
                <h4 className="quiz-heading">{quizDetails.name}</h4>

                {currentQuestion ? (
                    <div className="quiz-question-container">
                        {currentQuestion.image && (
                            <img 
                                className="quiz-question-image" 
                                src={currentQuestion.image} 
                                alt="Question" 
                            />
                        )}
                        <div className="quiz-question">
                            {currentQuestion.text}
                        </div>
                        {renderQuestionChoices()}
                        <div className="answer-info">
                            <p>Total answers: {totalAnswers}</p>
                            <p>Your current score: {score}</p>
                        </div>
                        <button 
                            className="quiz-submit-button" 
                            onClick={handleSubmitAnswer}
                        >
                            Submit Answer
                        </button>
                        <button 
                            className="quiz-end-button" 
                            onClick={handleEndQuiz}
                        >
                            End Quiz
                        </button>
                    </div>
                ) : (
                    <div>Waiting for the next question...</div>
                )}
            </div>
        </div>
    );
}

export default LiveQuizPage;
