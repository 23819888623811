import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../assessmentpage.css';

const Questionnaire = () => {
  const [questionnaire, setQuestionnaire] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const fetchQuestionnaire = async () => {
      try {
        setLoading(true);
        const response = await axios.get('/api/questionnaire/7/');
        console.log("Questionnaire Data:", response.data);
        setQuestionnaire(response.data);
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestionnaire();
  }, []);

  const handleAnswerChange = (questionId, choice) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: choice,
    }));
  };

  const handleSubmitAnswers = async () => {
    const questionnaireId = 7; // Static questionnaire ID
    const questionnaireResponses = questionnaire.questionnaire_questions.map((question) => ({
      question: question.id,
      user_answer: selectedAnswers[question.id] || "", // Default to empty if no answer is selected
    }));
  
    const data = {
      questionnaire: questionnaireId, // Including the questionnaire ID explicitly
      questionnaire_question_responses: questionnaireResponses,
      response_data: "Submitted", // Ensure this field aligns with the backend requirement
    };
  
    console.log("Submitting Data:", data); // For debugging
    try {
      const response = await axios.post(
        `/api/questionnaire/7/response/`, // Using the static ID for URL
        data
      );
      setMessage('Questionnaire submitted successfully!');
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questionnaire.questionnaire_questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  if (loading) return <p>Loading questionnaire...</p>;
  if (error) return <p>Error loading questionnaire: {error}</p>;

  return (
    <div className="outer-assessment-container">
      <div className="assessment-container">
        <div className="questionnaire">
          <h3>Questionnaire</h3>
          <h4>Questions</h4>

          {questionnaire.one_question_at_a_time ? (
            <div className="question-item">
              <p><strong>Question:</strong> {questionnaire.questionnaire_questions[currentQuestionIndex].text}</p>
              <ul>
                {questionnaire.questionnaire_questions[currentQuestionIndex].choices.map((choice, index) => (
                  <li key={index}>
                    <input
                      type="radio"
                      id={`question-${currentQuestionIndex}-choice-${index}`}
                      name={`question-${questionnaire.questionnaire_questions[currentQuestionIndex].id}`}
                      value={choice}
                      checked={selectedAnswers[questionnaire.questionnaire_questions[currentQuestionIndex].id] === choice}
                      onChange={() => handleAnswerChange(questionnaire.questionnaire_questions[currentQuestionIndex].id, choice)}
                    />
                    <label htmlFor={`question-${currentQuestionIndex}-choice-${index}`}>{choice}</label>
                  </li>
                ))}
              </ul>
              <button onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>
                Previous
              </button>
              <button onClick={handleNextQuestion} disabled={currentQuestionIndex === questionnaire.questionnaire_questions.length - 1}>
                {currentQuestionIndex === questionnaire.questionnaire_questions.length - 1 ? "Finish" : "Next"}
              </button>
            </div>
          ) : (
            questionnaire.questionnaire_questions.map((question) => (
              <div key={question.id} className="question-item">
                <p><strong>Question:</strong> {question.text}</p>
                <ul>
                  {question.choices.map((choice, index) => (
                    <li key={index}>
                      <input
                        type="radio"
                        id={`question-${question.id}-choice-${index}`}
                        name={`question-${question.id}`}
                        value={choice}
                        checked={selectedAnswers[question.id] === choice}
                        onChange={() => handleAnswerChange(question.id, choice)}
                      />
                      <label htmlFor={`question-${question.id}-choice-${index}`}>{choice}</label>
                    </li>
                  ))}
                </ul>
              </div>
            ))
          )}
          <button className="assessment-submit-button" onClick={handleSubmitAnswers}>
            Submit
          </button>
          {message && <p>{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;
